export const getSessionsData = {
  data: {
    sessions: {
      items: [
        {
          deviceId: '123abc',
          deviceType: 'SEAGATE',
          receivedTime: null,
          thingName: null,
          consumerId: 'c960ef81-dfcb-4feb-a5a6-d61bb2c7be72',
          sessionId: '1a40392d-b320-467f-a611-6abf772aced4',
          batteryLevel: 6,
          brushModel: 113,
          brushScore: 6,
          brushingDuration: 120,
          cleanTongue: 'DISABLED',
          coveragePercentage: 0.73,
          flossTeeth: 'DISABLED',
          gatewayModel: 178,
          gumBleeding: 'DISABLED',
          handleSessionId: 4,
          onEventCount: 5,
          pressureDistribution: '5555555555555555555555555555555555555559555555',
          pressureDuration: 0,
          pressureEventCount: 7,
          primaryBrushingMode: 'DAILY_CLEAN',
          rinseMouth: 'DISABLED',
          sessionStartTime: '2020-11-10T19:26:37+00:00',
          sessionType: 'GATEWAY',
          zonedBrushTime: null,
          zonedPressureTime: null
        },
        {
          deviceId: '123abc',
          deviceType: 'SEAGATE',
          receivedTime: null,
          thingName: null,
          consumerId: 'c960ef81-dfcb-4feb-a5a6-d61bb2c7be72',
          sessionId: 'bd59e6d0-2cec-4315-a755-9fc03d0cfdf3',
          batteryLevel: 7,
          brushModel: 113,
          brushScore: 2,
          brushingDuration: 120,
          cleanTongue: 'DISABLED',
          coveragePercentage: 0.73,
          flossTeeth: 'DISABLED',
          gatewayModel: 178,
          gumBleeding: 'DISABLED',
          handleSessionId: 2,
          onEventCount: 1,
          pressureDistribution: '5555555555555555555555555555555555555559555555',
          pressureDuration: 0,
          pressureEventCount: 9,
          primaryBrushingMode: 'DAILY_CLEAN',
          rinseMouth: 'DISABLED',
          sessionStartTime: '2020-11-10T19:21:59+00:00',
          sessionType: 'GATEWAY',
          zonedBrushTime: null,
          zonedPressureTime: null
        },
        {
          deviceId: '123abc',
          deviceType: 'SEAGATE',
          receivedTime: null,
          thingName: null,
          consumerId: 'c960ef81-dfcb-4feb-a5a6-d61bb2c7be72',
          sessionId: '63014459-bce1-45b2-aa66-b85b56e8da54',
          batteryLevel: 4,
          brushModel: 113,
          brushScore: 4,
          brushingDuration: 120,
          cleanTongue: 'DISABLED',
          coveragePercentage: 0.73,
          flossTeeth: 'DISABLED',
          gatewayModel: 178,
          gumBleeding: 'DISABLED',
          handleSessionId: 3,
          onEventCount: 4,
          pressureDistribution: '5555555555555555555555555555555555555559555555',
          pressureDuration: 0,
          pressureEventCount: 6,
          primaryBrushingMode: 'DAILY_CLEAN',
          rinseMouth: 'DISABLED',
          sessionStartTime: '2020-11-10T03:57:47+00:00',
          sessionType: 'GATEWAY',
          zonedBrushTime: null,
          zonedPressureTime: null
        },
        {
          deviceId: '123abc',
          deviceType: 'SEAGATE',
          receivedTime: null,
          thingName: null,
          consumerId: 'c960ef81-dfcb-4feb-a5a6-d61bb2c7be72',
          sessionId: '81703d06-3265-40f2-a88d-63ea06e49772',
          batteryLevel: 4,
          brushModel: 113,
          brushScore: 6,
          brushingDuration: 120,
          cleanTongue: 'DISABLED',
          coveragePercentage: 0.73,
          flossTeeth: 'DISABLED',
          gatewayModel: 178,
          gumBleeding: 'DISABLED',
          handleSessionId: 4,
          onEventCount: 2,
          pressureDistribution: '5555555555555555555555555555555555555559555555',
          pressureDuration: 0,
          pressureEventCount: 8,
          primaryBrushingMode: 'DAILY_CLEAN',
          rinseMouth: 'DISABLED',
          sessionStartTime: '2020-11-10T03:53:00+00:00',
          sessionType: 'GATEWAY',
          zonedBrushTime: null,
          zonedPressureTime: null
        },
        {
          deviceId: '123abc',
          deviceType: 'SEAGATE',
          receivedTime: null,
          thingName: null,
          consumerId: 'c960ef81-dfcb-4feb-a5a6-d61bb2c7be72',
          sessionId: 'da96cf9d-c51f-4c06-9780-7627242804ac',
          batteryLevel: 7,
          brushModel: 113,
          brushScore: 2,
          brushingDuration: 120,
          cleanTongue: 'DISABLED',
          coveragePercentage: 0.73,
          flossTeeth: 'DISABLED',
          gatewayModel: 178,
          gumBleeding: 'DISABLED',
          handleSessionId: 8,
          onEventCount: 3,
          pressureDistribution: '5555555555555555555555555555555555555559555555',
          pressureDuration: 0,
          pressureEventCount: 0,
          primaryBrushingMode: 'DAILY_CLEAN',
          rinseMouth: 'DISABLED',
          sessionStartTime: '2020-11-10T02:49:52+00:00',
          sessionType: 'GATEWAY',
          zonedBrushTime: null,
          zonedPressureTime: null
        }
      ]
    }
  }
};

export const deleteSessionData = {
  data: {
    deleteSession: '1a40392d-b320-467f-a611-6abf772aced4 Deleted Successfully'
  }
};

export const createSessionData = {
  data: {
    createSession: {
      sessionType: 'GATEWAY',
      deviceId: 'testabc123',
      deviceType: 'SEAGATE',
      consumerId: 'c960ef81-dfcb-4feb-a5a6-d61bb2c7be72',
      sessionId: '29d1c9d1-2b9c-45ab-8618-60f48c3862c8',
      batteryLevel: 5,
      brushScore: 2,
      brushingDuration: 120,
      cleanTongue: 'DISABLED',
      coveragePercentage: 0.73,
      flossTeeth: 'DISABLED',
      sessionStartTime: '2020-11-10T20:56:01+00:00',
      gumBleeding: 'DISABLED',
      handleSessionId: 2,
      gatewayModel: 178,
      brushModel: 113,
      onEventCount: 9,
      pressureDistribution: '5555555555555555555555555555555555555559555555',
      pressureDuration: 0,
      pressureEventCount: 8,
      primaryBrushingMode: 'DAILY_CLEAN',
      rinseMouth: 'DISABLED',
      zonedBrushTime: null
    }
  }
};
